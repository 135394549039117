import React from 'react';

import ArrowSmallIcon from '../../images/ArrowSmall';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { IInputOnChange, Input } from '../Inputs/Input';
import Select from '../Inputs/Select';

import style from './Join.scss';

import axios from 'axios';
import { isEmail, validPhoneNumber } from 'utils/helpers';

interface ISignUpProps {
  className?: string;
  trafficType?: string;
}

interface IPayload {
  [name: string]: string;
}

interface IInvalidFields {
  [name: string]: boolean;
}

interface ISignUpBannerState {
  payload: IPayload;
  invalidFields: IInvalidFields;
  errorMsg: string;
  isSending: boolean;
  isSubmitted: boolean;
  stepShown: number;
}

const requiredStepOne: string[] = ['firstname', 'lastname'];

const requiredStepThreeSell: string[] = [
  'productCategory',
  'leadProducerType',
  'trafficTypeSelect',
];

const requiredStepThreeBuy: string[] = ['advertiserType', 'productCategory'];

class JoinForm extends React.Component<ISignUpProps, ISignUpBannerState> {
  constructor(props: ISignUpProps) {
    super(props);
    this.state = {
      // request payload
      errorMsg: '',
      invalidFields: {},
      isSending: false,
      isSubmitted: false,
      payload: {
        trafficType: props.trafficType || '',
      },
      stepShown: 1,
    };
  }

  handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.handleSubmit();
  };

  handleSubmit = () => {
    const { isSending, payload } = this.state;
    if (isSending) {
      return;
    }

    this.setState({ isSending: true, errorMsg: '' });

    const url = process.env.JOIN_US_ENDPOINT || '';

    axios
      .post(url, JSON.stringify(payload))
      .then((res) => {
        this.setState({
          isSending: false,
          isSubmitted: true,
          payload: {},
        });
        setTimeout(() => {
          this.setState({ isSubmitted: false });
        }, 2000);
      })
      .catch((err) => {
        this.setState({ errorMsg: err.message, isSending: false });
      });
  };

  handleChange = (input: IInputOnChange) => {
    const { payload, invalidFields } = this.state;
    this.setState({
      invalidFields: { ...invalidFields, [input.name]: false },
      payload: { ...payload, [input.name]: input.value },
    });
  };

  handleSelectChange = (name: string, value: string) => {
    const { payload, invalidFields } = this.state;
    this.setState({
      invalidFields: { ...invalidFields, [name]: false },
      payload: { ...payload, [name]: value },
    });
  };

  handleBuyLeadsNext = () => {
    this.setState({
      payload: {
        trafficType: 'buy_leads',
      },
      stepShown: 1,
    });
  };

  handleSellLeadsNext = () => {
    this.setState({
      payload: {
        trafficType: 'sell_leads',
      },
      stepShown: 1,
    });
  };

  handleNext = () => {
    const { payload } = this.state;
    const step = this.state.stepShown;
    const type = this.state.payload.trafficType;

    switch (step) {
      case 1:
        const invalidFields: IInvalidFields = requiredStepOne.reduce(
          (o, name: string) => ({ ...o, [name]: !payload[name] }),
          {}
        );
        if (requiredStepOne.some((name) => invalidFields[name])) {
          this.setState({ invalidFields, errorMsg: 'Field is required.' });
          return;
        }
        this.setState((prevstate) => ({ stepShown: prevstate.stepShown + 1 }));
        break;
      case 2:
        if (!isEmail(payload.email)) {
          this.setState({ invalidFields: { email: true }, errorMsg: 'Invalid email.' });
          return;
        }
        if (!validPhoneNumber(payload.phone)) {
          this.setState({ invalidFields: { phone: true }, errorMsg: 'Invalid phone number.' });
          return;
        }
        this.setState((prevstate) => ({ stepShown: prevstate.stepShown + 1 }));
        break;
      case 3:
        if (type === 'sell_leads') {
          const invalidFields: IInvalidFields = requiredStepThreeSell.reduce(
            (o, name: string) => ({ ...o, [name]: !payload[name] }),
            {}
          );
          if (requiredStepThreeSell.some((name) => invalidFields[name])) {
            this.setState({ invalidFields, errorMsg: 'Field is required.' });
            return;
          }
        } else if (type === 'buy_leads') {
          const invalidFields: IInvalidFields = requiredStepThreeBuy.reduce(
            (o, name: string) => ({ ...o, [name]: !payload[name] }),
            {}
          );
          if (requiredStepThreeBuy.some((name) => invalidFields[name])) {
            this.setState({ invalidFields, errorMsg: 'Field is required.' });
            return;
          }
        }
        this.setState((prevstate) => ({ stepShown: prevstate.stepShown + 1 }));

        this.handleSubmit();
        break;
      default:
        break;
    }
  };

  handlePrev = () => {
    this.setState({
      errorMsg: '',
      invalidFields: {},
    });
    this.setState((prevstate) => ({ stepShown: prevstate.stepShown - 1 }));
  };

  render() {
    const { errorMsg, invalidFields, isSending } = this.state;

    const {
      firstname = '',
      lastname = '',
      email = '',
      phone = '',
      skypename = '',
      advertiserType = '',
      productCategory = '',
      leadProducerType = '',
      trafficType,
      trafficTypeSelect = '',
    } = this.state.payload;

    return (
      <>
        {this.state.stepShown > 1 && this.state.stepShown < 4 && (
          <ArrowSmallIcon className={style.backButton} onClick={this.handlePrev} rotate={180} />
        )}
        {(this.state.stepShown === 1 ||
          this.state.stepShown === 2 ||
          this.state.stepShown === 3) && (
          <div className={style.stepOne}>
            <h2>
              {trafficType === 'sell_leads' && 'Join the Lead Economy'}
              {trafficType === 'buy_leads' && 'Join the Lead Economy'}
            </h2>
            <p>
              {trafficType === 'sell_leads' &&
                'Please introduce yourself. Together, we will take your traffic to new heights.'}
              {trafficType === 'buy_leads' &&
                'With vetted, high quality traffic, connect to see why we are a superior lead provider. '}
            </p>
            <form className={style.form} onSubmit={this.handleSubmit}>
              <Input
                type="hidden"
                value={trafficType}
                name="trafficType"
                onChange={this.handleChange}
              />
              <div className="row">
                {this.state.stepShown === 1 && (
                  <>
                    <div className="col-12">
                      <h5>What is your name?</h5>
                    </div>
                    <div className="col-12 col-md-6">
                      <Input
                        value={firstname}
                        name="firstname"
                        className={style.inputWrapper}
                        onChange={this.handleChange}
                        placeholder="First Name"
                        helperText={errorMsg}
                        hasError={!!errorMsg && invalidFields.firstname}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <Input
                        value={lastname}
                        name="lastname"
                        className={style.inputWrapper}
                        onChange={this.handleChange}
                        placeholder="Last Name"
                        helperText={errorMsg}
                        hasError={!!errorMsg && invalidFields.lastname}
                      />
                    </div>
                    <div className="col-12">
                      <PrimaryButton
                        onClick={this.handleNext}
                        isLoading={isSending}
                        className={style.nextStep}
                      >
                        <ArrowSmallIcon />
                      </PrimaryButton>
                      {/* {errorMsg && !requiredStepThreeSell.some((name) => invalidFields[name]) ? (
                        <span className={cn(style.statusText, style.error)}>{errorMsg}</span>
                      ) : null} */}
                    </div>
                  </>
                )}
                {this.state.stepShown === 2 && (
                  <>
                    <div className="col-12">
                      <h5>What is the best way to reach you?</h5>
                    </div>
                    <div className="col-12 col-md-6">
                      <Input
                        value={email}
                        name="email"
                        className={style.inputWrapper}
                        onChange={this.handleChange}
                        placeholder="Email Adress"
                        helperText={errorMsg}
                        hasError={!!errorMsg && invalidFields.email}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <Input
                        type="tel"
                        mask={[
                          '(',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        value={phone}
                        name="phone"
                        className={style.inputWrapper}
                        onChange={this.handleChange}
                        placeholder="Phone Number"
                        helperText={errorMsg}
                        hasError={!!errorMsg && invalidFields.phone}
                      />
                    </div>
                    {trafficType === 'sell_leads' && (
                      <div className="col-12 col-md-6">
                        <Input
                          value={skypename}
                          name="skypename"
                          className={style.inputWrapper}
                          onChange={this.handleChange}
                          placeholder="Skype Name"
                          helperText={errorMsg}
                          hasError={!!errorMsg && invalidFields.skypename}
                        />
                      </div>
                    )}
                    <div className="col-12">
                      <PrimaryButton
                        onClick={this.handleNext}
                        isLoading={isSending}
                        className={style.nextStep}
                      >
                        <ArrowSmallIcon />
                      </PrimaryButton>
                      {/* {errorMsg && !requiredStepThreeSell.some((name) => invalidFields[name]) ? (
                        <span className={cn(style.statusText, style.error)}>{errorMsg}</span>
                      ) : null} */}
                    </div>
                  </>
                )}
                {this.state.stepShown === 3 && (
                  <>
                    <div className="col-12">
                      <h5>
                        {trafficType === 'sell_leads' && 'What kind of affiliate work do you do?'}
                        {trafficType === 'buy_leads' && 'Tell us about your product.'}
                      </h5>
                    </div>
                    {trafficType === 'buy_leads' && (
                      <div className="col-12 col-md-6">
                        <Select
                          value={advertiserType}
                          name="advertiserType"
                          options={[
                            { value: 'personal_loan_lender', label: 'Personal Loan Lender' },
                            { value: 'small_dollar_lender', label: 'Small Dollar Lender' },
                            { value: 'installment_lender', label: 'Installment Lender' },
                            { value: 'lender_finding_service', label: 'Lender Finding Service' },
                            { value: 'title_lender', label: 'Title Lender' },
                            { value: 'line_of_credit_lender', label: 'Line of Credit Lender' },
                            { value: 'credit_card', label: 'Credit Card' },
                            { value: 'debit_card', label: 'Debit Card' },
                            { value: 'insurance_provider', label: 'Insurance Provider' },
                            { value: 'mortgage_lender', label: 'Mortgage Lender' },
                          ]}
                          className={style.inputWrapper}
                          onChange={this.handleSelectChange}
                          placeholder="Advertiser Type"
                          isSearchable={false}
                          helperText={errorMsg}
                          hasError={!!errorMsg && invalidFields.advertiserType}
                        />
                      </div>
                    )}
                    <div className="col-12 col-md-6">
                      <Select
                        value={productCategory}
                        name="productCategory"
                        options={
                          trafficType === 'sell_leads'
                            ? [
                                { value: 'payday', label: 'Payday' },
                                { value: 'personal_loan', label: 'Personal Loan' },
                                { value: 'credit_cards', label: 'Credit Cards' },
                                { value: 'pre_paid_debit', label: 'Pre-Paid Debit' },
                                { value: 'mortgage', label: 'Mortgage' },
                                { value: 'debt', label: 'Debt' },
                                { value: 'biz_ops', label: 'Biz-Ops' },
                                { value: 'dating', label: 'Dating' },
                                { value: 'insurance', label: 'Insurance' },
                                { value: 'e_commerce', label: 'E-Commerce' },
                                { value: 'senior_care', label: 'Senior Care' },
                                { value: 'nutra', label: 'Nutra' },
                                { value: 'education', label: 'Education' },
                                { value: 'jobs', label: 'Jobs' },
                                { value: 'investing', label: 'Investing' },
                                { value: 'other', label: 'Other' },
                              ]
                            : [
                                { value: 'loans', label: 'Loans' },
                                { value: 'financial_services', label: 'Financial Services' },
                                { value: 'insurance', label: 'Insurance' },
                                { value: 'other_products', label: 'Other Products' },
                              ]
                        }
                        className={style.inputWrapper}
                        onChange={this.handleSelectChange}
                        placeholder="Product Category"
                        isSearchable={false}
                        helperText={errorMsg}
                        hasError={!!errorMsg && invalidFields.productCategory}
                      />
                    </div>
                    {trafficType === 'sell_leads' && (
                      <div className="col-12 col-md-6">
                        <Select
                          value={leadProducerType}
                          name="leadProducerType"
                          options={[
                            { value: 'publisher', label: 'Publisher' },
                            { value: 'network', label: 'Network' },
                            { value: 'referral_partner', label: 'Referral Partner' },
                            { value: 'other', label: 'Other' },
                          ]}
                          className={style.inputWrapper}
                          onChange={this.handleSelectChange}
                          placeholder="Lead Producer Type"
                          isSearchable={false}
                          helperText={errorMsg}
                          hasError={!!errorMsg && invalidFields.leadProducerType}
                        />
                      </div>
                    )}
                    {trafficType === 'sell_leads' && (
                      <div className="col-12 col-md-6">
                        <Select
                          value={trafficTypeSelect}
                          name="trafficTypeSelect"
                          options={[
                            { value: 'email', label: 'Email' },
                            { value: 'sms', label: 'SMS' },
                            { value: 'ppc', label: 'PPC' },
                            { value: 'display', label: 'Display' },
                            { value: 'social', label: 'Social' },
                            { value: 'banner', label: 'Banner' },
                            { value: 'other', label: 'Other' },
                          ]}
                          className={style.inputWrapper}
                          onChange={this.handleSelectChange}
                          placeholder="Traffic Type"
                          isSearchable={false}
                          helperText={errorMsg}
                          hasError={!!errorMsg && invalidFields.trafficTypeSelect}
                        />
                      </div>
                    )}
                    <div className="col-12">
                      <PrimaryButton
                        onClick={this.handleNext}
                        isLoading={isSending}
                        className={style.nextStep}
                      >
                        <span>Submit</span> <ArrowSmallIcon />
                      </PrimaryButton>
                      {/* {errorMsg && !requiredStepThreeSell.some((name) => invalidFields[name]) ? (
                        <span className={cn(style.statusText, style.error)}>{errorMsg}</span>
                      ) : null} */}
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        )}
        {this.state.stepShown === 4 && (
          <div className={style.stepTwo}>
            <h2>We’ll contact you shortly!</h2>
            <p>
              Thank you for getting in touch with us. We value you time and will be contacting you
              as soon as possible.
            </p>
          </div>
        )}
      </>
    );
  }
}

export default JoinForm;
