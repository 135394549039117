import React, { useState } from 'react';

import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../components/Buttons/SecondaryButton';
import JoinForm from '../../components/Forms/Join';
import Modal from '../../components/Modal/index';
import ArrowSmallIcon from '../../images/ArrowSmall';
import CheckmarkIcon from '../../images/Checkmark';

import style from './EarnMore.scss';

import cn from 'classnames';

interface IEarnMoreProps {
  className?: string;
  isHiddenTitle?: boolean;
  isBuyLeads?: boolean;
}

export default function EarnMore(props: IEarnMoreProps) {
  const { className, isHiddenTitle = false, isBuyLeads = false } = props;
  const [isJoinModalShown, setJoinModalShown] = useState(false);
  const [trafficType, setTrafficType] = useState('');

  // Contact Modal Toggle
  const showJoinModalWithStepSell = () => {
    setJoinModalShown(true);
    setTrafficType('sell_leads');
  };

  const showJoinModalWithStepBuy = () => {
    setJoinModalShown(true);
    setTrafficType('buy_leads');
  };

  const hideJoinModal = () => {
    setJoinModalShown(false);
    setTrafficType('');
  };

  return (
    <div className={cn(className, style.earnMore)}>
      {!isHiddenTitle && (
        <section className={style.title}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h2>
                  {isBuyLeads ? 'Ready to get more leads?' : 'Ready to earn more?'}
                  <small>
                    As an advanced tech company, Lead Economy is uniquely positioned to deliver{' '}
                    <br className="d-none d-lg-block" />
                    bold results for both affiliates and advertisers.
                  </small>
                </h2>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className={style.joinNow}>
        <div className="container">
          <div className="row">
            <div className={cn('col-12 col-sm-6', style.boxJoin)}>
              {isBuyLeads ? (
                <>
                  <h5>
                    Join the top affiliate <br className="d-none d-md-block" />
                    platform.
                  </h5>
                  <ul>
                    <li>
                      <CheckmarkIcon />A vast network of buyers
                    </li>
                    <li>
                      <CheckmarkIcon />
                      High converting offers
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Quick payouts
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Dedicated support
                    </li>
                  </ul>
                  <SecondaryButton onClick={showJoinModalWithStepSell} className={style.secondary}>
                    Sell Leads <ArrowSmallIcon />
                  </SecondaryButton>
                </>
              ) : (
                <>
                  <h5>
                    Learn More
                    <br className="d-none d-md-block" /> About Advertisers
                  </h5>
                  <ul>
                    <li>
                      <CheckmarkIcon />
                      Vetted quality leads
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Detailed analytics
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Quality assurance filters
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Extraordinary support
                    </li>
                  </ul>
                  <SecondaryButton onClick={showJoinModalWithStepBuy} className={style.secondary}>
                    Buy Leads <ArrowSmallIcon />
                  </SecondaryButton>
                </>
              )}
            </div>
            <div className={cn('col-12 col-sm-6 order-sm-first', style.boxJoin, style.boxSelected)}>
              {isBuyLeads ? (
                <>
                  <h5>
                    Get more high-quality
                    <br className="d-none d-md-block" /> leads.
                  </h5>
                  <ul>
                    <li>
                      <CheckmarkIcon />
                      Vetted quality leads
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Detailed analytics
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Quality assurance filters
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Extraordinary support
                    </li>
                  </ul>
                  <PrimaryButton onClick={showJoinModalWithStepBuy}>
                    Buy Leads <ArrowSmallIcon />
                  </PrimaryButton>
                </>
              ) : (
                <>
                  <h5>
                    Join the top affiliate <br className="d-none d-md-block" />
                    platform.
                  </h5>
                  <ul>
                    <li>
                      <CheckmarkIcon />A vast network of buyers
                    </li>
                    <li>
                      <CheckmarkIcon />
                      High converting offers
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Quick payouts
                    </li>
                    <li>
                      <CheckmarkIcon />
                      Dedicated support
                    </li>
                  </ul>
                  <PrimaryButton onClick={showJoinModalWithStepSell}>
                    Sell Leads <ArrowSmallIcon />
                  </PrimaryButton>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal
        className={style.joinModal}
        isShown={isJoinModalShown}
        isCloseButtonShown
        onClose={hideJoinModal}
      >
        <JoinForm trafficType={trafficType} />
      </Modal>
    </div>
  );
}
