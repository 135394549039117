import React, { useEffect } from 'react';

import { observeIntersection } from '../../utils/intersectionObserver';

import cn from 'classnames';

interface IContentSectionProps {
  inViewThreshold?: number;
  inViewAnimationClass?: string;
  className?: string;
  children: React.ReactNode;
}

export const ContentSection = (props: IContentSectionProps) => {
  const { inViewThreshold = 0.3, inViewAnimationClass, className, children } = props;

  useEffect(() => {
    if (inViewAnimationClass) {
      observeIntersection({
        className: inViewAnimationClass,
        targetClassName: 'sectionAnimationTarget',
        threshold: inViewThreshold,
      });
    }
  }, []);

  return (
    <section
      className={cn(className, {
        ['sectionAnimationTarget']: !!inViewAnimationClass,
      })}
    >
      {children}
    </section>
  );
};
