import React, { Component } from 'react';

import dedicationBGImage from '../../images/index/dedication-background.jpg';

import style from './DedicatedSupport.scss';

import cn from 'classnames';

interface IProps {
  className?: string;
  isTextShown?: boolean;
}

export class DedicatedSupport extends Component<IProps> {
  render() {
    const { className } = this.props;

    return (
      <section className={cn(className, style.dedicatedSupport)}>
        <div className="container">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-sm-9 col-lg-6">
              <div className={style.headerTextBox}>
                <h2>
                  Dedicated <br className="d-none d-sm-block" />
                  24/7 support.
                  <small>
                    You’ll always have a direct line to our team of
                    <br className="d-none d-xl-block" /> account managers.
                  </small>
                </h2>
              </div>
            </div>
            <div className="col-12 col-sm-3 col-lg-6 order-sm-first">
              <img src={dedicationBGImage} alt="Background Lady" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
