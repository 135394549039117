import React, { useState } from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { ContentSection } from '../components/ContentSection/ContentSection';
import { DedicatedSupport } from '../components/DedicatedSupport/DedicatedSupport';
import EarnMore from '../components/EarnMore/EarnMore';
import JoinForm from '../components/Forms/Join';
import Modal from '../components/Modal/index';
import heroBGImageMobile from '../images/Advertiser/background-mobile.jpg';
import heroBGImage from '../images/Advertiser/background.jpg';
import dashboardMobileImage from '../images/Advertiser/dashboard-mobile.png';
import dashboardImage from '../images/Advertiser/dashboard.png';
import ArrowIcon from '../images/ArrowSmall';
import AggregatorIcon from '../images/Icons/Icon-10';
import LeadsIcon from '../images/Icons/Icon-11';
import StrongIcon from '../images/Icons/Icon-12';
import PersonIcon from '../images/Icons/Icon-9';

import style from '../styles/pages/brands.scss';

import cn from 'classnames';

interface IAdvertiserProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
}

const Advertiser = ({ location }: IAdvertiserProps) => {
  const [isJoinModalShown, setJoinModalShown] = useState(false);
  const [trafficType, setTrafficType] = useState('');

  // Join Modal Toggle
  const showJoinModalWithStepBuy = () => {
    setJoinModalShown(true);
    setTrafficType('buy_leads');
  };

  const hideJoinModal = () => {
    setJoinModalShown(false);
    setTrafficType('');
  };

  return (
    <>
      <MetaData location={location} data={{}} />
      <Modal
        className={style.joinModal}
        isShown={isJoinModalShown}
        isCloseButtonShown
        onClose={hideJoinModal}
      >
        <JoinForm trafficType={trafficType} />
      </Modal>
      <Layout isHome isWhiteHeader headerClassName={style.header}>
        <section className={style.hero}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-md-3 col-lg-5 col-xl-6 order-md-last">
                <img className="d-none d-md-block" src={heroBGImage} alt="Background Lady" />
                <img className="d-md-none" src={heroBGImageMobile} alt="Background Lady" />
              </div>
              <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                <div className={style.headerTextBox}>
                  <h1>
                    Expand your empire.
                    <small>
                      We have a proven record of consistently delivering high quality leads with no
                      BS.
                    </small>
                  </h1>
                  <PrimaryButton onClick={showJoinModalWithStepBuy}>
                    Start Now <ArrowIcon />
                  </PrimaryButton>
                </div>
                <h2>Why us?</h2>
              </div>
            </div>
          </div>
        </section>

        <section className={style.family}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="row">
                  <ContentSection
                    className={cn('col-12 col-sm-6 col-lg-12', style.col)}
                    inViewAnimationClass={style.isInView}
                  >
                    <div>
                      <PersonIcon />
                      <h5>Consistent high-quality leads.</h5>
                      <p>
                        We have established a reputation in the industry giving us access to the top
                        affiliates and networks.
                      </p>
                    </div>
                  </ContentSection>
                  <ContentSection
                    className={cn('col-12 col-sm-6 col-lg-12', style.col)}
                    inViewAnimationClass={style.isInView}
                  >
                    <div>
                      <AggregatorIcon />
                      <h5>Not Just an Aggregator.</h5>
                      <p>
                        On average, at least half of our traffic is generated by our internal
                        marketing teams.
                      </p>
                    </div>
                  </ContentSection>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={style.dashboard}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-lg-7 col-xl-6 offset-lg-5 offset-xl-6">
                <h2>Work with a brand you can trust.</h2>
              </div>
              <div className="col-12 col-md-6">
                <img src={dashboardImage} alt="Dashboard" />
                <img className={style.dashboardMobile} src={dashboardMobileImage} alt="Dashboard" />
              </div>
              <div className="col-12 col-md-4 col-xl-3 offset-md-2 offset-xl-3">
                <div className="row">
                  <ContentSection
                    className={cn('col-12 col-sm-6 col-md-12', style.col)}
                    inViewAnimationClass={style.isInView}
                  >
                    <div>
                      <LeadsIcon className={style.icon} />
                      <h5>Filter Leads</h5>
                      <p>
                        Leverage custom filters to receive only the leads that meet your business’
                        needs.
                      </p>
                    </div>
                  </ContentSection>
                  <ContentSection
                    className={cn('col-12 col-sm-6 col-md-12', style.col)}
                    inViewAnimationClass={style.isInView}
                  >
                    <div>
                      <StrongIcon className={style.icon} />
                      <h5>Strong KPI’s</h5>
                      <p>
                        99% up time and high redirect rates ensure a great consumer experience and
                        better conversion for our partners.
                      </p>
                    </div>
                  </ContentSection>
                </div>
              </div>
            </div>
          </div>
        </section>

        <DedicatedSupport />
        <EarnMore isBuyLeads />
        <BigBanner />
      </Layout>
    </>
  );
};

export default Advertiser;
