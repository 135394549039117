import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <path
          d="M31 16a7 7 0 1 1-14 0 7 7 0 0 1 14 0zM10 41c0-7.73 6.267-14 14-14s14 6.27 14 14"
          stroke="#4468FF"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default React.memo(Icon);
