import React from 'react';

import ErrorIcon from '../../images/Error';
import { isMobile } from '../../utils/helpers';

import style from './Select.scss';

import cn from 'classnames';
import memoize from 'memoize-one';
import Select, { components } from 'react-select';

interface IOptionType {
  label: string;
  value: string;
  selectInRange?: number[];
}

const { Placeholder } = components;
const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <components.ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) => {
        return child && child.type !== Placeholder ? child : null;
      })}
    </components.ValueContainer>
  );
};
export interface ISelectInputProps {
  name: string;
  icon?: JSX.Element;
  options: IOptionType[];
  value?: string;
  defaultValue?: IOptionType[];
  defaultInputValue?: string;
  hasError?: boolean;
  isMulti?: boolean;
  helperClassName?: string;
  placeholder?: string;
  helperText?: string;
  closeMenuOnSelect?: boolean;
  autoFocus?: boolean;
  menuIsOpen?: boolean;
  className?: string;
  isSearchable?: boolean;
  borderRadius?: string;
  openMenuOnFocus?: boolean;
  defaultMenuIsOpen?: boolean;
  isOptionDisabled?: (option: IOptionType) => boolean;
  onChange: (name: string, value: any) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}
const memoizeValue = memoize((options: IOptionType[], value: string | undefined) => {
  return options.find((o: IOptionType) => o.value === value);
});
export default function SelectInput(props: ISelectInputProps) {
  const {
    name,
    options,
    onChange,
    onFocus,
    onBlur,
    isSearchable = true,
    openMenuOnFocus = true,
    placeholder,
    borderRadius,
    helperClassName,
    helperText,
    value,
    defaultValue,
    className,
    hasError,
    ...restProps
  } = props;
  const customValue = memoizeValue(options, value);
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.name = name;
    switch (e.type) {
      case 'focus':
        if (typeof onFocus === 'function') {
          onFocus(e);
        }
        break;
      case 'blur':
        if (typeof onBlur === 'function') {
          onBlur(e);
        }
        break;
      default:
        break;
    }
  };
  const handleChange = (selectedOption: any) => {
    onChange(name, selectedOption.value);
  };
  const handleChangeNative = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    onChange(name, value);
  };
  return (
    <div
      className={cn(className, style.select, {
        [style.error]: hasError,
      })}
    >
      {isMobile() ? (
        <div className={cn(style.mobileSelect, className)}>
          <select
            {...restProps}
            className={cn({ [style.hasValue]: customValue })}
            name={name}
            onChange={handleChangeNative}
            onFocus={handleFocus as any}
            onBlur={handleFocus as any}
          >
            <option className={style.optionPlaceholder} value="" hidden>
              {placeholder}
            </option>
            {options.map((item, index) => {
              return (
                <option key={index} value={item.value as any}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>
      ) : (
        <Select
          {...restProps}
          name={name}
          options={options}
          classNamePrefix="reactSelect"
          onChange={handleChange}
          blurInputOnSelect={false}
          menuPlacement="auto"
          isSearchable={isSearchable}
          value={customValue}
          defaultValue={defaultValue}
          placeholder={placeholder}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          openMenuOnFocus={openMenuOnFocus}
          indicatorSeparator={null}
        />
      )}
      {hasError ? (
        <>
          <ErrorIcon className={style.errorIcon} />
          <span className={style.errorMessage}>{helperText}</span>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
