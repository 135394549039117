import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const CheckmarkIcon = (props: IIconProps) => {
  const { height = 10, width = 14, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.43L5.896 8 12 2"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default React.memo(CheckmarkIcon);
