import React from 'react';

interface IIconProps {
  className?: string;
}

const Icon = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <path
          d="M30.112 27.591c7.393-1.879 11.071-7.009 11.997-11.703h-6.983M19.342 27.591C11.95 25.712 8.271 20.582 7.345 15.888h6.983"
          stroke="#4468FF"
          strokeWidth="3"
        />
        <path
          d="M35.314 12.005h0-21.308c0 8.322 4.083 15.676 10.34 20.217a6.33 6.33 0 0 1-5.515 3.97v2.746h11.658v-2.746a6.33 6.33 0 0 1-5.515-3.97c6.256-4.541 10.34-11.895 10.34-20.217z"
          stroke="#4468FF"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default React.memo(Icon);
